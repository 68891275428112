import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Contentcomponent />
      <div id="light">
        <div id="lineh1"></div>
        <div id="lineh2"></div>
        <div id="lineh3"></div>
        <div id="lineh4"></div>
        <div id="lineh5"></div>
        <div id="lineh6"></div>
        <div id="lineh7"></div>
        <div id="lineh8"></div>
        <div id="lineh9"></div>
        <div id="lineh10"></div>
        <div id="lineh11"></div>
        <div id="lineh12"></div>
      </div>
      <Footercomponent />
    </div>
  );
}

function Header() {
  return (
    <div className="Header">
      <img alt="logo" className="logo" src="images/logo.png"></img>
    </div>
  );
}

function Contentcomponent() {
  return (
    <div className="content">
      <table>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  );
}

function Footercomponent() {
  return (
    <footer className="footer">
        <a style={{color:"white"}} href="mailto:navi25190@gmail.com">Author: Naveen Kishor</a>
    </footer>
  );
}

export default App;
